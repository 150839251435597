import '../assets/css/login.css';

import Formsy from 'formsy-react';
import { Link, navigate } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ButtonLoader from '../components/buttonLoader';
import ErrrorHandel from '../components/ErrrorHandel';
import MyInput from '../components/MyInput';
import bottomLine from '../images/login-line.svg';
import * as actionCreators from '../store/actions';


import sideImg from '../images/gray/logincar.png';
import Layout from "../components/layout"
import SEO from "../components/seo"
import userIcon from "../images/avatar.svg"
import Footer from '../components/footer';
import lineImage from "../images/line2.png"

class ForgotPassPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            successMsg: '',
            dealertoken:null
        };
    }
    componentDidMount() {
        this.getDealerToken();
    }
    getDealerToken = () => {
        if(global.dealertoken){
            this.setState({ dealertoken: global.dealertoken })
            return global.dealertoken;
        } else {
            let data = this.props.location.search;
            if (data !== '') {
                const search = data.substring(1);
                let param = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
                let dt = param.dealertoken ? param.dealertoken : null;
                this.setState({ dealertoken: dt });
                return dt;
            } else {
                this.setState({ dealertoken: null });
                return null;
            }
        }
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showButtonLoader, hideButtonLoader } = this.props;
        const {dealertoken} = this.state;
        showButtonLoader()
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/forgotPassword'+(dealertoken?'/?dealertoken='+dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email:model.email
            })
        })
        .then(res => res.json())
        .then((res) => {
            if(res.status === 1){
                this.setState({successMsg: 'Please check your email, you will receive a password reset link.'})
                this.refs['forgotPassForm'].reset();
            } else if(res.status === 0){
                this.setState({ successMsg: 'We cannot find an account with that email address.' })
            } 
            hideButtonLoader();
        });

        /* APPLLOCLIENT
            .mutate({
                mutation: FORGOT_PASSWORD_MUTATION,
                variables: {
                    record: {
                        email: model.email,
                    },
                }
            })
            .then(res => {
                hideButtonLoader();
                if (res.data && res.data.auth && res.data.auth.forgotpassword && res.data.auth.forgotpassword.email) {
                    this.setState({successMsg: 'Please check your email.We send you mail to reset password'})
                    setTimeout(() => {
                        this.setState({successMsg: ''})
                        this.refs['forgotPassForm'].reset();
                    }, 1500);
                }
            }); */
    }

    close() {
        if (this.props.location && this.props.location.state && this.props.location.state.prevPath) {
            navigate(this.props.location.state.prevPath);
        } else {
            navigate("/"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
    }

    render() {

        const { canSubmit, successMsg, dealertoken } = this.state;
        const { buttonLoader } = this.props;
        return (
            <Layout layoutType="main">
            <SEO title="Forgot Password" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />

            <div className={dealertoken?"login-container grayish":"login-container"}>
                <div className="left">
                    <img className="sideimg" src={sideImg} alt="" />
                </div>
                <div className="right">


                    <Formsy ref="forgotPassForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                        <ErrrorHandel formName={'forgotPassForm'} self={this} />
                        <div className="sign-in-container">
                            <h2 className="title">Forgot Password</h2>
                            {/* <p className="subtitle">Enter your email address to reset your password</p> */}
                            <div className="form-group error">
                                <img className="txticon" src={userIcon} alt="email" />
                                <MyInput
                                    name="email"
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    validations="isEmail"
                                    validationError="Please enter valid email"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <span>{successMsg}</span>

                            <div className="form-group error">
                            <Link className="forgot-pass" to={"/login"+(dealertoken?'/?dealertoken='+dealertoken:'')}>Login</Link>
                            </div>

                            <button className={!canSubmit ? 'btn disabled' : 'btn'} disabled={!canSubmit} type="submit">
                                <ButtonLoader isLoading={buttonLoader} label={'Reset'} />
                            </button>
                            
                            
                        </div>
                    </Formsy>
                    {!dealertoken &&
                    <div className="login-line">
                        <img src={bottomLine} alt="line" />
                    </div>
                    }
                </div>
            </div>
            {!dealertoken &&
            <>
            <div className="break-section">
                    <img src={lineImage} alt="line" />
                </div>
            <Footer />
            </>
            }
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassPage);